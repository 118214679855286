












































































import Vue from 'vue';
import vSelect from 'vue-select';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import moment from 'moment';
import axios from 'axios';

export default Vue.extend({
  components: {
    DatePicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    const range = [moment().subtract(1, 'days').valueOf(), moment().valueOf()];
    return {
      display: false,
      alarmLevels: [
        { id: 1, txt: '資訊' },
        { id: 2, txt: '警告' },
        { id: 3, txt: '嚴重' },
      ],
      columns: [
        {
          key: 'time',
          label: '時間',
          sortable: true,
          formatter: (v: number) => moment(v).format('lll'),
        },
        {
          key: 'level',
          label: '等級',
          sortable: true,
          formatter: (v: number) => {
            switch (v) {
              case 1:
                return '資訊';

              case 2:
                return '警告';

              case 3:
                return '錯誤';
            }
          },
        },
        {
          key: 'src',
          label: '來源',
          sortable: true,
          formatter: (src: string) => {
            let tokens = src.split(':');
            switch (tokens[0]) {
              case 'I':
                return `設備:${tokens[1]}`;

              case 'T':
                return `測項:${tokens[1]}`;

              case 'S':
                if (tokens[1] === 'System') return `系統`;
                else return `系統:${tokens[1]}`;
              default:
                return src;
            }
          },
        },
        {
          key: 'info',
          label: '詳細資訊',
          sortable: true,
        },
      ],
      rows: [],
      form: {
        range,
        alarmLevel: 1,
      },
    };
  },
  methods: {
    async query() {
      this.display = true;
      const url = `/AlarmReport/${this.form.alarmLevel}/${this.form.range[0]}/${this.form.range[1]}`;
      const res = await axios.get(url);
      const ret = res.data;
      this.rows = ret;
    },
    rowClass(item: any, type: any) {
      if (!item || type !== 'row') return;
      switch (item.level) {
        case 1:
          return 'table-success';

        case 2:
          return 'table-warning';

        case 3:
          return 'table-danger';
      }
    },
  },
});
